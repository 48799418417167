// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {

  apiKey: "AIzaSyDKPCs6sekKkQ3DD4QMBWpeOv728QcMmcI",

  authDomain: "wlap-776cb.firebaseapp.com",

  databaseURL: "https://wlap-776cb-default-rtdb.firebaseio.com",

  projectId: "wlap-776cb",

  storageBucket: "wlap-776cb.appspot.com",

  messagingSenderId: "588397616482",

  appId: "1:588397616482:web:3b92e3ccd83d390b42603b",

  measurementId: "G-VR0R6D31VD"

};







// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export { app };